import { useContext } from 'react';
import useDidUpdateEffect from './useDidUpdateEffect';
import EmitEventsContext from '@/storychief/context/EmitEvents';

/**
 * Allows you to respond to a side effect triggered by an event elsewhere in the codebase. Useful
 * for interactions between sibling components.
 * @param {Function} effect - The function that will be called when an event is emitted.
 * @param {string} eventName
 * @returns {void}
 */
export default function useEmitEventEffect(effect, eventName) {
  const context = useContext(EmitEventsContext);

  useDidUpdateEffect(() => {
    if (context?.events[eventName]) {
      effect(context.events[eventName].data);
    }
  }, [context?.events[eventName]]);
}
